import { __decorate, __extends, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, instanceAvailability, cordovaInstance, checkAvailability } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var FileTransfer = /** @class */function (_super) {
  __extends(FileTransfer, _super);
  function FileTransfer() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    /**
     * Error code rejected from upload with FileTransferError
     * Defined in FileTransferError.
     *      FILE_NOT_FOUND_ERR: 1   Return when file was not found
     *      INVALID_URL_ERR: 2,     Return when url was invalid
     *      CONNECTION_ERR: 3,      Return on connection error
     *      ABORT_ERR: 4,           Return on aborting
     *      NOT_MODIFIED_ERR: 5     Return on '304 Not Modified' HTTP response
     *
     * @enum {number}
     */
    _this.FileTransferErrorCode = {
      FILE_NOT_FOUND_ERR: 1,
      INVALID_URL_ERR: 2,
      CONNECTION_ERR: 3,
      ABORT_ERR: 4,
      NOT_MODIFIED_ERR: 5
    };
    return _this;
  }
  /**
   * Creates a new FileTransfer object
   *
   * @returns {FileTransferObject}
   */
  FileTransfer.prototype.create = function () {
    return new FileTransferObject();
  };
  FileTransfer.ɵfac = /* @__PURE__ */(() => {
    let ɵFileTransfer_BaseFactory;
    return function FileTransfer_Factory(t) {
      return (ɵFileTransfer_BaseFactory || (ɵFileTransfer_BaseFactory = i0.ɵɵgetInheritedFactory(FileTransfer)))(t || FileTransfer);
    };
  })();
  FileTransfer.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: FileTransfer,
    factory: FileTransfer.ɵfac
  });
  FileTransfer.pluginName = "FileTransfer";
  FileTransfer.plugin = "cordova-plugin-file-transfer";
  FileTransfer.pluginRef = "FileTransfer";
  FileTransfer.repo = "https://github.com/apache/cordova-plugin-file-transfer";
  FileTransfer.platforms = ["Amazon Fire OS", "Android", "Browser", "iOS", "Ubuntu", "Windows", "Windows Phone"];
  FileTransfer = __decorate([], FileTransfer);
  return FileTransfer;
}(AwesomeCordovaNativePlugin);
export { FileTransfer };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();
var FileTransferObject = /** @class */function () {
  function FileTransferObject() {
    if (checkAvailability(FileTransfer.getPluginRef(), null, FileTransfer.getPluginName()) === true) {
      this._objectInstance = new (FileTransfer.getPlugin())();
    }
  }
  FileTransferObject.prototype.upload = function (fileUrl, url, options, trustAllHosts) {
    return cordovaInstance(this, "upload", {
      "successIndex": 2,
      "errorIndex": 3
    }, arguments);
  };
  FileTransferObject.prototype.download = function (source, target, trustAllHosts, options) {
    return cordovaInstance(this, "download", {
      "successIndex": 2,
      "errorIndex": 3
    }, arguments);
  };
  FileTransferObject.prototype.onProgress = function (listener) {
    var _this = this;
    return function () {
      if (instanceAvailability(_this) === true) {
        _this._objectInstance.onprogress = listener;
      }
    }();
  };
  FileTransferObject.prototype.abort = function () {
    return cordovaInstance(this, "abort", {
      "sync": true
    }, arguments);
  };
  FileTransferObject.plugin = "cordova-plugin-file-transfer";
  FileTransferObject.pluginName = "FileTransfer";
  FileTransferObject = __decorate([__metadata("design:paramtypes", [])], FileTransferObject);
  return FileTransferObject;
}();
export { FileTransferObject };
