import { isPlatformServer } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import type { FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import type { FileEntry, IFile } from '@awesome-cordova-plugins/file/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Auth } from '@freelancer/auth';
import { Pwa } from '@freelancer/pwa';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Observable } from 'rxjs';
import { BehaviorSubject, firstValueFrom, ReplaySubject } from 'rxjs';

export interface DownloadTask {
  readonly id: string;
  readonly name: string;
  readonly changes$: Observable<FileDownloadEvent>;
  readonly mimeType$: Observable<string>;
  cancel(): void;
}

export type FileDownloadEvent = {
  totalBytes?: number;
  transferedBytes?: number;
  /** Progress percentage (0-100) */
  progressPercentage?: number;
} & (
  | {
      status: 'running';
    }
  | {
      status: 'success';
      fileUrl: string;
    }
  | {
      status: 'error';
      errorCode:
        | FileDownloadError.UNKNOWN
        | FileDownloadError.PERMISSION_NOT_GRANTED;
    }
);

export enum FileDownloadError {
  UNKNOWN = 'file_download_unknown',
  PERMISSION_NOT_GRANTED = 'file_download_permission_not_granted',
}

/**
 * Service to download a file
 *
 * @remarks
 *
 * If this service does not trigger the file to download but instead displays it
 * in the browser, this is because the `Content-Disposition` header is wrong
 * on the backend response.
 *
 * WARNING: do not use `window.open` as a workaround, as it would break
 * PWA/TWA/Native mobile support.
 *
 * @export
 */
@UntilDestroy({ className: 'FileDownload' })
@Injectable({
  providedIn: 'root',
})
export class FileDownload {
  private downloadQueueSubject$ = new ReplaySubject<DownloadTask>(1);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private androidPermissions: AndroidPermissions,
    private auth: Auth,
    private file: File,
    private pwa: Pwa,
    private transfer: FileTransfer,
    private errorHandler: ErrorHandler,
  ) {}

  /**
   * Returns an observable that emits the created DownloadTask
   * every time a user triggers a file download.
   */
  getDownloadTaskStream(): Observable<DownloadTask> {
    return this.downloadQueueSubject$.asObservable();
  }

  /**
   * Download a file from a given URL
   */
  download(url: string, name: string): void {
    if (isPlatformServer(this.platformId)) {
      throw new Error(
        'Cannot trigger file download on page rendering. Please check code logic.',
      );
    }
    if (!url.startsWith('http')) {
      // TODO: T225788 throw on invalid URLs once existing violations are fixed.
      this.errorHandler.handleError(
        new Error(`Invalid non-absolute download URL "${url}"`),
      );
    }

    if (!this.pwa.isNative()) {
      // In browsers: trigger download and rely on browser UX
      window.location.href = url;
      return;
    }
    // In apps: manually download using FileTransfer

    // Create a stream to emit all the FileTransfer progress events
    const subject$ = new BehaviorSubject<FileDownloadEvent>({
      status: 'running',
    });
    let fileTransfer: FileTransferObject;
    const mimeType$ = new ReplaySubject<string>(1);
    (async () => {
      if (this.pwa.getPlatform() === 'android') {
        // Do not need to require WRITE_EXTERNAL_STORAGE in Android 13+ (SDK 33)
        // Require it will cause an exception
        const doNotNeedPermission =
          await this.pwa.hasMinimumNativeOsMajorVersion(13);
        if (!doNotNeedPermission) {
          const { hasPermission } =
            await this.androidPermissions.checkPermission(
              this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
            );
          if (!hasPermission) {
            const { hasPermission: hasAcceptedPermission } =
              await this.androidPermissions.requestPermission(
                this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
              );
            if (!hasAcceptedPermission) {
              subject$.next({
                status: 'error',
                errorCode: FileDownloadError.PERMISSION_NOT_GRANTED,
              });
              subject$.complete();
              return;
            }
          }
        }
      }

      const destinationUrl =
        this.pwa.getPlatform() === 'android'
          ? `${this.file.externalRootDirectory}/Download/`
          : this.file.dataDirectory;
      fileTransfer = this.transfer.create();
      fileTransfer.onProgress(event => {
        subject$.next({
          status: 'running',
          progressPercentage: event.total
            ? 100 * (event.loaded / event.total)
            : 0,
          transferedBytes: event.loaded,
          totalBytes: event.total,
        });
      });
      // FIXME: T267853 - some file download endpoints require authentication
      // this may not work properly if we switch to DownloadManager or similar
      firstValueFrom(
        this.auth.getAuthorizationHeader().pipe(untilDestroyed(this)),
      )
        .then(header =>
          fileTransfer.download(
            url,
            `${destinationUrl}${name}`,
            false, // trustAllHosts
            {
              // convert HttpHeader back into object
              headers: Object.fromEntries(
                header.keys().map(key => [key, header.get(key)]),
              ),
            },
          ),
        )
        .then((entry: FileEntry) => {
          subject$.next({
            status: 'success',
            fileUrl: entry.toURL(),
          });
          entry.file((file: IFile) => {
            mimeType$.next(file.type);
          });
          subject$.complete();
        })
        .catch(error => {
          // We do not want to track those errors as these are expected to
          // sometimes happen, e.g.  network failures
          console.error(error);
          // TODO: T267853 - show file download error UI state
          subject$.next({
            status: 'error',
            errorCode: FileDownloadError.UNKNOWN,
          });
          subject$.complete();
        });
    })();
    this.downloadQueueSubject$.next({
      id: `${name}${Math.random().toFixed(10)}`,
      name,
      changes$: subject$.asObservable(),
      mimeType$: mimeType$.asObservable(),
      cancel: () => fileTransfer.abort(),
    });
  }

  /**
   * Download a blob, and rename with the given filename
   *
   * @privateRemarks
   *
   * For IE browsers, blobs are saved using the msSaveBlob method, whilst other
   * browsers will be saved by creating a blob, then creating an invisible
   * anchor, and then triggering a download with the given filename.
   */
  saveBlob(blob: Blob, filename: string): void {
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
    }
  }
}
